<template>
  <section>

  </section>
</template>

<script>
var CryptoJS = require("crypto-js");

export default {
  mounted() {
    let key = CryptoJS.AES.decrypt(this.$route.params.id, 'secret')
    console.log(key.toString(CryptoJS.enc.Utf8));
  }
}
</script>
